body {
  --black: #111111;
  --white: #fdfdfd;
  --green: #22cc88;
  --blue: #0055ff;
  --purple: #8855ff;
  --red: #E2001A;
  --orange: #ee4444;
  --yellow: #ffcc00;
  --mustard: #ffaa00;
  --background:#0d2c4f;
  --accent: var(--white);
  margin: 0;
  padding: 0;
  background-color: var(--background);
  color: var(--accent);
}
@font-face {
  font-family: "Roboto Mono";
   font-weight: normal;
}

* {
  font-family: "Roboto Mono";
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

article {
  max-width: 500px;
  padding: 20px;
  margin: 0 auto;
}

.progress-bar {
  position: fixed;
  z-index: 1004;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: #b13a25;
  transform-origin: 0%;
}
.box {
  width: 100vw;
}
.st0{fill: var(--white);}
.st1{fill: var(--red);}